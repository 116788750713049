exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-blog-archive-tsx": () => import("./../../../src/templates/blog/BlogArchive.tsx" /* webpackChunkName: "component---src-templates-blog-blog-archive-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/Post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-case-studie-c-sautohop-tsx": () => import("./../../../src/templates/case-studie/CSautohop.tsx" /* webpackChunkName: "component---src-templates-case-studie-c-sautohop-tsx" */),
  "component---src-templates-case-studie-c-snarusa-tsx": () => import("./../../../src/templates/case-studie/CSnarusa.tsx" /* webpackChunkName: "component---src-templates-case-studie-c-snarusa-tsx" */),
  "component---src-templates-case-studie-c-snormapress-tsx": () => import("./../../../src/templates/case-studie/CSnormapress.tsx" /* webpackChunkName: "component---src-templates-case-studie-c-snormapress-tsx" */),
  "component---src-templates-case-studie-case-studie-tsx": () => import("./../../../src/templates/case-studie/CaseStudie.tsx" /* webpackChunkName: "component---src-templates-case-studie-case-studie-tsx" */),
  "component---src-templates-jobs-job-offer-tsx": () => import("./../../../src/templates/jobs/JobOffer.tsx" /* webpackChunkName: "component---src-templates-jobs-job-offer-tsx" */),
  "component---src-templates-pages-page-home-tsx": () => import("./../../../src/templates/pages/PageHome.tsx" /* webpackChunkName: "component---src-templates-pages-page-home-tsx" */),
  "component---src-templates-pages-page-oferta-tsx": () => import("./../../../src/templates/pages/PageOferta.tsx" /* webpackChunkName: "component---src-templates-pages-page-oferta-tsx" */),
  "component---src-templates-pages-pagekariera-tsx": () => import("./../../../src/templates/pages/Pagekariera.tsx" /* webpackChunkName: "component---src-templates-pages-pagekariera-tsx" */),
  "component---src-templates-pages-pagekoncepcja-komunikacji-marki-tsx": () => import("./../../../src/templates/pages/Pagekoncepcja-komunikacji-marki.tsx" /* webpackChunkName: "component---src-templates-pages-pagekoncepcja-komunikacji-marki-tsx" */),
  "component---src-templates-pages-pageo-firmie-tsx": () => import("./../../../src/templates/pages/Pageo-firmie.tsx" /* webpackChunkName: "component---src-templates-pages-pageo-firmie-tsx" */),
  "component---src-templates-pages-pagesocial-media-tsx": () => import("./../../../src/templates/pages/Pagesocial-media.tsx" /* webpackChunkName: "component---src-templates-pages-pagesocial-media-tsx" */),
  "component---src-templates-projects-single-project-tsx": () => import("./../../../src/templates/projects/singleProject.tsx" /* webpackChunkName: "component---src-templates-projects-single-project-tsx" */)
}

